<template>
  <div class="password-modify">
    <el-card class="box-card">
      <!-- 标题 -->
      <div slot="header" class="clearfix">
        <span>修改密码</span>
      </div>

      <!-- 内容 -->
      <div class="content">
        <el-form
          label-width="100px"
          :model="passwordData"
          :rules="rules"
          ref="pwdform"
          status-icon
        >
          <!-- 原密码 -->
          <el-form-item label="原密码" prop="oldpwd">
            <el-input v-model="passwordData.oldpwd" show-password></el-input>
          </el-form-item>

          <!-- 新密码 -->
          <el-form-item label="新密码" prop="newpwd">
            <el-input v-model="passwordData.newpwd" show-password></el-input>
          </el-form-item>

          <!-- 确认新密码 -->
          <el-form-item label="确认新密码" prop="newpwdAgain">
            <el-input
              v-model="passwordData.newpwdAgain"
              show-password
            ></el-input>
          </el-form-item>

          <!-- 按钮 -->
          <el-form-item>
            <el-button @click="submitModify" type="primary">修改</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
// 引入ajax函数
import { modifyPwd } from "@/api/login";
// 引入工具函数lcoal
import local from "@/utils/local";

export default {
  // 数据
  data() {
    // 验证旧密码
    const checkOldPwd = async (rule, value, callback) => {
      // 判断
      if (!value) {
        callback(new Error("请输入原密码"));
      }
    };

    // 验证新密码
    const checkNewPwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else if (!/^[a-zA-Z0-9]{6,18}$/.test(value)) {
        callback(new Error("字母/数字 6 ~ 18 位"));
      } else if (this.passwordData.oldpwd === value) {
        callback(new Error("新密码不能和旧密码相同"));
      } else {
        callback();
      }
    };

    // 定义验证两次输入密码是否一致
    const checkPassAgain = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordData.newpwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      // 表单数据
      passwordData: {
        oldpwd: "",
        newpwd: "",
        newpwdAgain: "",
      },

      // 验证规则
      rules: {
        oldpwd: { required: true, validator: checkOldPwd, trigger: "blur" },
        newpwd: { required: true, validator: checkNewPwd, trigger: "blur" },
        newpwdAgain: {
          required: true,
          validator: checkPassAgain,
          trigger: "blur",
        },
      },
    };
  },

  // 方法
  methods: {
    // 提交验证
    async submitModify() {
      // 验证表单是否正确
      this.$refs.pwdform.validate(async (valid) => {
        // 判断是否表单验证通过
        if (valid) {
          let data = {
            old_pwd: this.passwordData.oldpwd,
            new_pwd: this.passwordData.newpwd,
            confirm_pwd: this.passwordData.newpwdAgain,
          };
          console.log('data',data);
          // 发送修改的ajax
          let res = await modifyPwd(data);
          let { code } = res; // 解构对象
          // 判断code为0时
          if (code === 200) {
            local.clear(); // 清空本地
            this.$message("请重新登录!");
            this.$router.push("/login"); // 跳转到登录
          }
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          return;
        }
      });
    },

    // 重置表单
    resetForm() {
      this.$refs.pwdform.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  .el-input {
    width: 250px;
  }
}
</style>